import React from "react"
import { graphql } from "gatsby"
import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import TwoCol from "gatsby-theme-wirehead-bulma/src/twocol"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import SchemaCollection from "../components/schema-collection"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SocialMenu from "../components/socialmenu"
import SocialShare from "../components/socialshare"
import PostLink from "../components/post-link"
import Breadcrumbs from "gatsby-theme-wirehead-bulma/src/breadcrumbs"

export default function CollectionTemplate({ data, location, pageContext }) {
  const { mdx, allSitePage } = data
  const { body } = mdx

  var pages = data.allSitePage.edges.map(edge => {
    return <PostLink key={edge.node.path} post={edge} />
  })

  return (
    <Layout>
      <SEO
        title={pageContext.title}
        pathname={location.pathname}
        description={pageContext.excerpt}
      />
      <SchemaCollection
        pathname={location.pathname}
        pages={data.allSitePage.edges}
        description={pageContext.excerpt}
        breadcrumbs={pageContext.breadcrumbs}
      />
      <Breadcrumbs
        depth={pageContext.depth}
        breadcrumbs={pageContext.breadcrumbs}
      />
      <TwoCol
        left={
          <div className="content">
            <h1>{pageContext.title}</h1>
            <MDXRenderer pages={allSitePage}>{body}</MDXRenderer>
            {pages}
          </div>
        }
        right={
          <>
            <aside className="menu">
              <SocialMenu />
            </aside>
            <SocialShare link={location.pathname} />
          </>
        }
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String, $path: String) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        type
        description
      }
      excerpt(pruneLength: 300)
    }
    allSitePage(filter: { context: { parent: { eq: $path } } }) {
      edges {
        node {
          context {
            id
            name
            parent
            title
            depth
            date
            excerpt
            type
          }
          id
          path
        }
      }
    }
  }
`
