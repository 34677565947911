import React from "react"
import { Link } from "gatsby"

import audioIcon from "../../content/assets/audio-1.svg"
import docIcon from "../../content/assets/document-1.svg"
import folderIcon from "../../content/assets/folder-1.svg"

const PostLink = ({ post }) => {
  let icon = docIcon
  if (post.node.context.type === "MusicRecording") {
    icon = audioIcon
  }
  if (post.node.context.type === "Collection") {
    icon = folderIcon
  }
  return (
    <article className="media" key={post.node.path}>
      <figure className="media-left">
        <p className="icon is-large">
          <img src={icon} alt="icon" className="is-text-centered" />
        </p>
      </figure>
      <div className="media-content">
        <Link to={post.node.path} key={post.node.path}>
          {post.node.context.title}
        </Link>
        <br />
        {post.node.context.excerpt}
      </div>
    </article>
  )
}
export default PostLink
